export default {
  fonts: {
    main: "Noto Sans",
    // font-family: medium-content-sans-serif-font, "Lucida Grande", "Lucida Sans Unicode", "Lucida 
    code: "Roboto Mono, monospace",
  },
  colors: {
    // main1: "hsl(207, 70%, 59%)",
    main1: "#357ae8",
    main2: "hsl(207, 70%, 94%)",
    dark1: "hsl(227, 2%, 12%)",
    dark2: "hsl(227, 2%, 26%)",
    dark3: "hsl(227, 2%, 64%)",
    dark4: "#757575",
    dark5: "#C8C8C8",
    light1: "hsl(0, 0%, 100%)",
    light2: "hsl(0, 0%, 95%)",
  },
  breakpoints: {
    mobile: "only screen and (max-width: 50rem)",
    tablet: "only screen and (max-width: 65rem)",
  },
  spacings: {
    xxSmall: ".25rem",
    xSmall: ".5rem",
    small: "1rem",
    medium: "2rem",
    large: "1rem",
    xLarge: "3rem",
    xxLarge: "3rem",
  },
  animations: {
    button: "box-shadow 0.3s ease",
    link: "color 0.2 ease",
  },
  shadows: {
    shadow1: "2px 5px 20px rgba(30, 30, 31, 0.05)",
  },
}

